import React from 'react'
import { Layout } from '../containers/Layout'
import { Text } from '../components/Text'
import { Container } from '../components/grid/Container'
import { MobileContent } from '../components/grid/MobileContent'
import { Mapa } from '../partials/index/Mapa'
import { Separator } from '../components/Separator'
import { Seo } from '../partials/Seo'
import ObtenerCitaSvg from '../images/covid19/obtener-cita.svg'
import ObligacionesPacienteSvg from '../images/covid19/obligaciones-paciente.svg'
import SalidaDelCentroSvg from '../images/covid19/salida-del-centro.svg'
import LavadoManosPng from '../images/covid19/lavado-manos.png'
import { Block } from '../components/Block'
import { List } from '../components/List'
import { media } from '../theme/mixins/media'
import styled from 'styled-components/macro'
import { TarifaSection } from '../partials/index/TarifaSection'
import { rem } from 'polished'

const Page = () => {
  return (
    <Layout fullWidth>
      <Seo title="Medidas de seguridad frente al COVID-19 - Centro Feeling" />
      <Container marginBottom="size9">
        <MobileContent>
          <BodyWrapper>
            <Text size="h1" family="merienda" center marginBottom="size6">
              Medidas de seguridad frente al COVID-19
            </Text>

            <Text as="div" size="large" weight="light" center>
              Estás en buenas manos, recomendaciones para poder atenderte en el
              Centro
            </Text>

            <Separator />

            <Text size="h3" center uppercase marginBottom="size3">
              Para obtener cita
            </Text>

            <Block textAlign="center" marginBottom="size5">
              <ObtenerCitaSvg />
            </Block>

            <MedidasList>
              <List.Item>
                Las citas se darán vía telefónica/whatsapp. Se recopilará la
                máxima información (patología, síntomas, datos personales) con
                el fin de actualizar la ficha del paciente y minimizar los
                tiempos de exposición y de habla en el tratamiento. Se
                preguntará modo de pago para poder hacer el trámite rápido.
              </List.Item>
              <List.Item>
                No acudir a la cita y avisar, si entre que se le da la cita y
                acude a la misma presenta síntomas compatibles con proceso
                Covid-19: fiebre, tos seca, dolor faríngeo, congestión nasal,
                fatiga, anosmia, diarrea entre otros.
              </List.Item>
            </MedidasList>

            <Text
              size="h3"
              center
              uppercase
              marginTop="size7"
              marginBottom="size3"
            >
              El paciente debe
            </Text>

            <Block textAlign="center" marginBottom="size5">
              <ObligacionesPacienteSvg />
            </Block>

            <MedidasList>
              <List.Item>
                El paciente traerá una toalla para taparse durante la sesión de
                fisioterapia. Se facilitará una bolsa de plástico para depositar
                los objetos personales (ropa, mochila, ...).
              </List.Item>
              <List.Item>
                Acuda aseado y con ropa cómoda. Debe retirarse pulseras,
                collares o pendientes metálicos antes de entrar al centro.
              </List.Item>
              <List.Item>
                Acudir con mascarilla (que tendrá que llevar durante toda la
                sesión), si no lo hace se le proveerá una previo pago de la
                misma.
              </List.Item>
              <List.Item>
                Se desinfectará el calzado en el felpudo al entrar con un spray
                desinfectante. Se le podrian dar calzas con las que acceder a la
                sala de fisioterapia.
              </List.Item>
              <List.Item>
                Debe acudir solo al centro sin acompañante salvo sea
                imprescindible (menores, personas dependientes...).
              </List.Item>
              <List.Item>
                Debe llegar a la hora pactada. Esperar fuera de la instalación.
                Se abrirá la puerta a su hora. Se darán una serie de
                instrucciones. Evitar tocar superficies y objetos en la medida
                de lo posible al entrar.
              </List.Item>
              <List.Item>
                Se le proporcionará hidrogel alcohólico al entrar para que se
                desinfecte las manos durante 20 segundos, en la sala se le puede
                invitar, si procede, a lavarse las manos de nuevo.
              </List.Item>
              <List.Item>
                No usar el wc salvo urgencia, informar del uso para su posterior
                limpieza.
              </List.Item>
            </MedidasList>

            <Text
              size="h3"
              center
              uppercase
              marginTop="size7"
              marginBottom="size3"
            >
              A la salida del centro
            </Text>

            <Block textAlign="center" marginBottom="size5">
              <SalidaDelCentroSvg />
            </Block>

            <MedidasList>
              <List.Item>
                A la salida se desinfectará el calzado de nuevo. Se echará
                hidrogel alcohólico en las manos.
              </List.Item>
              <List.Item>
                Se recomiendo acudir directamente al domicilio, realizar hábitos
                de higiene básicos (desinfección de calzado y manos, lavar ropa
                o ducharse) como prevención.
              </List.Item>
            </MedidasList>

            <Block marginTop="size5">
              <img
                src={LavadoManosPng}
                alt="Cómo lavarse las manos"
                style={{ maxWidth: '100%' }}
              />
            </Block>
          </BodyWrapper>
        </MobileContent>
      </Container>
      <TarifaSection />
      <Mapa />
    </Layout>
  )
}

export default Page

const BodyWrapper = styled.div`
  ${media.tablet`
    max-width: ${rem(900)};
    margin-left: auto;
    margin-right: auto;
  `}
`

const MedidasList = styled(List)`
  ${media.desktop`
    column-count: 2;
  `}
`
